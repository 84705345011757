import { FC } from 'react';
import styled from 'styled-components';
import { TYPOGRAPHY, COLORS } from '@landing/constants';
import { URL_PUBLIC } from '@/shared/urls';
import Container from '../Container';
import { BreakpointsEnum } from '@landing/types';
import StepSlider from '../StepSlider';
import { slides } from './data';
import { BannerThoughtfulSuggestion } from '../ClientHeroSection/components';

const HowItWorksSection: FC = () => {
  return (
    <Root id="how-it-work">
      <StyledContainer>
        <Title>Как это работает</Title>
        <StepSlider
          slides={slides}
          buttonText="Подобрать"
          buttonLink={URL_PUBLIC.allTherapists}
        />
      </StyledContainer>
      <StyledBannerThoughtfulSuggestion isWhite={false} />
    </Root>
  );
};

export default HowItWorksSection;

const Root = styled.div`
  width: 100%;
  background: ${COLORS.dark100};
`;

const StyledContainer = styled(Container)`
  padding: 100px 140px;

  @media (max-width: ${BreakpointsEnum.lgm}px) {
    padding: 60px;
  }

  @media (max-width: ${BreakpointsEnum.md}px) {
    padding: 40px;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding: 40px 20px 59px;
  }
`;

const Title = styled.h2`
  margin-bottom: 37px;
  ${TYPOGRAPHY.heading2SemiBold64};
  color: ${COLORS.dark800};

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-bottom: 30px;
    ${TYPOGRAPHY.headline1Semibold40};
  }
`;

const StyledBannerThoughtfulSuggestion = styled(BannerThoughtfulSuggestion)`
  margin: 0 auto;

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin: 0 16px 60px 16px;
    width: unset;
  }
`;
