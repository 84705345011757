import { URL_PUBLIC } from '@/shared/urls';
import Link from 'next/link';

const WhatsAppSupport = () => (
  <a
    href="https://api.whatsapp.com/send/?phone=%2B79198707755&text&type=phone_number&app_absent=0"
    onClick={(e) => e.stopPropagation()}
    target="_blank"
    rel="noreferrer"
  >
    Службу заботы
  </a>
);

export const faqList = [
  {
    title: 'Зачем нужна консультация с психологом и как она проходит?',
    text: 'Онлайн-консультация дает прекрасную возможность проработать глубинные проблемы, которые мешают вам жить. Онлайн-формат консультаций с психологом создает комфортную атмосферу и помогает вам полностью раскрыться. Вы находитесь в знакомой обстановке, чувствуете себя более расслабленно и делитесь своими переживаниями.',
  },

  {
    title: 'Как подобрать психолога?',
    text: (
      <>
        Вы можете воспользоваться одним из 3-х способов. Первый способ - это
        подбор психолога через анкету, где вы отвечаете на несколько вопросов и,
        в зависимости от ваших ответов, вам будут предложены психологи,
        соответствующих вашим критериям.
        <br />
        <br />
        Второй способ - это самостоятельный поиск{` `}
        <Link href={{ pathname: URL_PUBLIC.allTherapists }}>в каталоге</Link>
        {` `}с помощью удобных фильтров, где представлены все психологи.
        <br />
        <br />
        Третий способ - это написать в нашу{` `}
        <WhatsAppSupport />
        {` `}и указать тему, на которую хотели бы поговорить со специалистом, и
        мы пришлём вам нескольких психологов на выбор под ваш запрос. Наши
        специалисты Службы заботы подробно расскажут, как забронировать сессию и
        подберут удобный для вас временной интервал для первой сессии.
      </>
    ),
  },

  {
    title: 'Что делать, если меня не устроил специалист или качество сессии?',
    text: (
      <>
        Наш сервис создан для того, чтобы вы нашли специалиста, подходящего
        именно вам. Для этого мы проделали большую работу и все специалисты прошли
        серьезный отбор перед тем, как попасть к нам на платформу. Более
        подробно о нашем отборе специалистов, вы можете прочитать в нашем
        разделе FAQ. Если после первой сессии вы поняли, что психолог вам не
        подошёл, напишите в нашу{` `}
        <WhatsAppSupport />,{` `}
        мы подберём вам другого специалиста и оплатим повторную сессию за свой
        счёт.
      </>
    ),
  },

  {
    title: 'Какая продолжительность консультации?',
    text: 'Международный формат продолжительности сессии - 50 минут, дополнительно мы предоставляем 10 минут для её завершения.',
  },

  {
    title: 'Какие у вас требования к психологам?',
    text: (
      <>
        <ol>
          <li>Высшее психологическое образование</li>
          <li>Сертификация в одном из современных методов терапии</li>
          <li>Опыт многолетней личной терапии</li>
          <li>Опыт работы не менее 3-х лет</li>
          <li>Прохождение всех этапов отбора:</li>
        </ol>

        <ul>
          <li>Интервью</li>
          <li>Проверка документов</li>
          <li>
            Тестовая сессия с реальным клиентом в присутствии комиссии
            специалистов команды Поговорим.онлайн.
          </li>
        </ul>
      </>
    ),
  },

  {
    title: 'Как и в какой момент оплачивается сессия?',
    text: 'Для бронирования сессии вам нужно выбрать дату и время консультации, после чего привязать карту для последующей оплаты. В момент привязки карты с неё спишется платеж, подтверждающий, что на карте есть средства, который сразу же вернется вам обратно. Оплата в полном размере списывается при бронировании и не подлежит возврату менее, чем за 24 часа до начала сессии.',
  },

  {
    title: 'Как перенести или отменить сессию?',
    text: 'Сессию можно перенести или отменить в личном кабинете не позднее чем за 24 часа до ее начала. Правило 24 часов — это стандартное правило в практике психотерапии. Оно обеспечивает дисциплину с обеих сторон и не дает пойти на поводу у чувств, которые могут возникнуть при приближении к действительно важным и глубоким темам.',
  },

  {
    title: 'С какого устройства я могу подключиться к сервису?',
    text: 'Вы можете подключиться с компьютера, а также со смартфона или планшета, работающего на одной из последних версий IOS и Android. Для качественной видеосвязи желательно иметь доступ к WiFi на время сессии.',
  },

  {
    title: 'Насколько конфиденциальна моя информация?',
    text: 'Все, что вы рассказываете при работе с психологом — остаётся между вами. Это правило соблюдается всеми участниками нашей команды в соответствии с международным этическим кодексом. Кроме того, вы сами выбираете, какой объём информации о себе оставить в личном кабинете, а мы обязуемся не передавать ее третьим лицам.',
  },

  {
    title: 'Как часто нужно ходить на консультации?',
    text: 'Продолжительность и регулярность терапии вы определяете самостоятельно вместе со своим специалистом. Это зависит от вашего запроса и метода, в котором работает специалист. Мы рекомендуем посещать сессии раз в неделю.',
  },

  {
    title: 'Сколько нужно ходить к психологу чтобы получить результат?',
    text: 'Вам нужно ходить столько, сколько понадобится именно вам. Это индивидуальный вопрос, и вы обязательно обсудите это со своим психологом на первой сессии. Мы рекомендуем брать не менее 10 консультаций с психологом, а по окончании этого периода свериться со своими ощущениями и принимать решение о дальнейшей терапии.',
  },

  {
    title: 'Как подготовиться к сессии?',
    text: 'Перед первой сессией обязательно воспользуйтесь вкладкой “Проверить оборудование" в боковом меню личного кабинета и убедитесь, что подключение работает корректно. За сутки и за час до начала сессии мы присылаем напоминание, где также будет продублирована ссылка на проверку вашего устройства. Непосредственно перед началом сессии выберите удобное и тихое место, где вы будете чувствовать себя комфортно и следуйте нашей инструкции по подключению к сессии.',
  },

  {
    title: 'Как проходит сессия?',
    text: 'Консультация проходит на платформе через встроенный модуль видеосвязи. На основном экране вы будете видеть психолога, а на маленьком вы будете видеть себя и кнопки управления.',
  },

  {
    title: 'Куда обращаться, если остались вопросы?',
    text: (
      <>
        По всем вопросам, включая помощь в подборе психолога, вы можете
        обращаться в нашу <WhatsAppSupport /> или написать нам на почту{' '}
        <a
          href="mailto:prosto@pogovorim.online"
          onClick={(e) => e.stopPropagation()}
        >
          prosto@pogovorim.online
        </a>
      </>
    ),
  },
];
