import { FC, useRef } from 'react';
import styled from 'styled-components';
import { TYPOGRAPHY, COLORS } from '@landing/constants';
import { URL_PUBLIC } from '@/shared/urls';
import { BreakpointsEnum } from '@landing/types';
import { items } from './data';
import Container from '../Container';
import Link from 'next/link';
import apx from '@landing/constants/adaptivepx';

const TherapySection: FC = () => {
  const rootRef = useRef(null);

  return (
    <Root ref={rootRef} id="therapy">
      <Container className="container">
        <TherapyTitle>Работаем с любыми запросами</TherapyTitle>
        <Bubbles>
          {items.map((item, index) => (
            <Bubble href={item.link} key={index}>
              <p>{item.text}</p>
            </Bubble>
          ))}
        </Bubbles>
        <Link href={URL_PUBLIC.allTherapists}>ещё 40+ запросов</Link>
      </Container>
    </Root>
  );
};

export default TherapySection;

const Root = styled.section`
  background: ${COLORS.dark100};
  overflow: hidden;
  padding: ${apx.d(78)} 0 ${apx.d(85)} 0 !important;

  .container {
    max-width: ${apx.d(1450)};
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding-left: ${apx.l(20)};
    padding-right: ${apx.l(20)};
    padding: ${apx.l(66)} 0 ${apx.l(71)} 0 !important;
    .container {
      max-width: none;
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    padding-left: ${apx.m(25)};
    padding-right: ${apx.m(25)};
    padding: ${apx.m(47)} 0 ${apx.m(47)} 0 !important;
  }

  a {
    display: block;
    width: max-content;
    position: relative;
    margin: 0 auto;
    font-weight: 700;
    font-size: ${apx.d(30)};
    line-height: ${apx.d(37)};
    color: ${COLORS.nativeBlack};
    text-decoration: underline;
    text-decoration-skip-ink: none;
    z-index: 0;

    &::after {
      content: '';
      width: ${apx.d(224)};
      height: ${apx.d(24)};
      background: #fdff95;
      position: absolute;
      bottom: -${apx.d(10)};
      left: ${apx.d(29)};
      z-index: -1;
    }

    @media (max-width: ${BreakpointsEnum.tablet}px) {
      font-size: ${apx.l(25)};

      &::after {
        left: 0;
        bottom: -${apx.l(13)};
        right: 0;
        margin: auto;

        width: ${apx.l(168)};
        height: ${apx.l(18)};
      }
    }

    @media (max-width: ${BreakpointsEnum.mb}px) {
      font-size: ${apx.m(20)};
      &::after {
        left: -${apx.m(10)};
        bottom: -${apx.m(4)};
        width: 106%;
      }
    }
  }
`;

const TherapyTitle = styled.h2`
  margin-bottom: ${apx.d(45)};
  ${TYPOGRAPHY.bodyBold36};
  font-size: ${apx.d(48)};
  color: ${COLORS.nativeBlack};
  text-align: center;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(33)};
    font-size: ${apx.l(36)};
    line-height: ${apx.l(44)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(26)};
    font-size: ${apx.m(30)};
    line-height: ${apx.m(37)};
  }
`;

const Bubbles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: ${apx.d(20)};
  column-gap: ${apx.d(20)};
  margin-bottom: ${apx.d(78)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(32)};
    row-gap: ${apx.l(18)};
    column-gap: ${apx.l(15)};
    justify-content: center;
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    flex-direction: column;
    row-gap: ${apx.m(19)};
    margin-bottom: ${apx.m(26)};
  }
`;

const Bubble = styled.a`
  min-width: ${apx.d(260)};
  height: ${apx.d(110)};
  border-radius: ${apx.d(265)};
  border: ${apx.d(1)} dashed ${COLORS.green};
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 ${apx.d(4)} ${apx.d(55)} 0 rgba(58, 54, 219, 0.05);
  cursor: pointer;

  &:after {
    content: none !important;
  }

  p {
    ${TYPOGRAPHY.bodyMedium20};
    line-height: ${apx.d(24)};
    color: ${COLORS.nativeBlack};
    text-align: center;
    /* text-decoration: underline; */
    text-decoration-skip-ink: none;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    min-width: ${apx.l(195)} !important;
    height: ${apx.l(83)} !important;

    p {
      font-size: ${apx.l(15)} !important;
      line-height: ${apx.l(18)};
      max-width: ${apx.l(115)};
    }
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    height: ${apx.m(52)} !important;
    width: 100% !important;
    min-width: auto;

    p {
      max-width: none;
      display: block;
      width: 100%;
      font-size: ${apx.m(16)} !important;
      text-underline-offset: ${apx.m(3)};
    }
  }
`;
