import { URL_PUBLIC } from '@/shared/urls';

export const items = [
  {
    text: 'Тревога и беспокойство',
    link: `${URL_PUBLIC.allTherapists}/trevoga-stress/`,
  },
  {
    text: 'Нет энергии и сил',
    link: `${URL_PUBLIC.allTherapists}/apatiya-otsutstvie-sil-uvlecheniy-tseley-smysla/`,
  },
  { text: 'Депрессия', link: `${URL_PUBLIC.allTherapists}/depressiya/` },
  {
    text: 'Разобраться в себе',
    link: `${URL_PUBLIC.allTherapists}/nastroenie-napryamuyu-stalo-vliyaty-na-rabotu-karyeru-uchebu/`,
  },
  {
    text: 'Сложности в отношениях',
    link: `${URL_PUBLIC.allTherapists}/problemy-v-semye-ili-otnosheniyakh/`,
  },
];

export const dialogs = [
  ['Такой опыт у Вас уже есть, давайте попробуем иначе', 'Я сам справлюсь'],
  ['Ходят здоровые', 'К психологам ходят больные'],
  [
    'Вклад в эмоциональный интеллект — это лучшая инвестиция для себя',
    'Это дорого',
  ],
  ['Мы будем рады развеять эту фантазию', 'Мне уже ничего не поможет'],
  ['Друзья это хорошо, но субъективно', 'Я лучше поговорю с друзьями'],
  ['Мы рады будем разделить Вашу радость', 'У меня и так все хорошо'],
];
