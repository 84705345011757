/* eslint-disable @next/next/no-html-link-for-pages */
import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { TYPOGRAPHY, COLORS } from '@landing/constants';
import { URL_PUBLIC } from '@/shared/urls'
import { BreakpointsEnum } from '@landing/types';
import Container from '../Container';
import { Button, ButtonLink } from '@landing/ui/components';
import {
  BusinessLogo,
  ChevronRight,
  ClientLogo,
  Dzen,
  TelegramOutline,
  TherapistLogo,
  YouTube,
} from '@landing/ui/iconComponents';
import {
  useBusinessModal,
  UseBusinessModalEnum,
} from '@landing/pageComponents/BusinessPage/useBusinessModal';
import { useRouter } from 'next/router';
import apx from '@landing/constants/adaptivepx';
import { useToggle } from '@landing/hooks';
import SelectSpecialist from '../SelectSpecialist';
import { useAppSelector } from '@/app/store/hooks';
import { selectors } from '@/app/store';

interface Props {
  role?: 'client' | 'therapist' | 'business';
}

const Footer: FC<Props> = ({ role }) => {

  const [modal, { openModal }] = useBusinessModal();
  const router = useRouter();
  const { state: isCorpInfoOpened, toggle: toggleCorpInfo } = useToggle();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);


  const userMetadata = useAppSelector(selectors.auth.selectMetadata);
  const isMedsiUser = userMetadata.integration === 'medsi';

  const options = [

    { label: 'Для себя', isActive: true, path: '/all-therapists' },
    { label: 'Для ребенка', isActive: true, path: ' /child-therapists' },
    // { label: 'Для семьи и пар', isActive: false, path: '/' },
    // { label: 'Супервизоры', isActive: false, path: '/' }

  ];

  useEffect(() => {

    const handleResize = () => {

      setIsMobile( window.innerWidth < 768 );
      setIsTablet( window.innerWidth >= 768 && window.innerWidth < 1024 );

    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {

      window.removeEventListener('resize', handleResize);

    };

  }, []);


  const isDesktop = !( isMobile || isTablet );
  const isMainPage = router.pathname === '/';

  const customStyles = ( isDesktop ) ? {} : {

    display: 'flex',
    justifyContent: 'center',
    position:   ( isMobile && isMainPage ) ? 'fixed' : 'relative',
    zIndex:     '5',
    top:        'unset',
    bottom:     isMobile   ? isMainPage ? `${ apx.m( 22 ) }` : `-${ apx.m( 22 ) }` : apx.l(30),
    left:       isMainPage ? '0'  : isMobile ? '0' : apx.l(68),
    right:     !isMainPage ? '0'    : '0',
    margin:    !isMainPage ? 'auto' : 'auto',

  }

  const customDropdownListStyles = isDesktop ? {} : {

    transform:    'translateX(-50%)',

    top:          'unset',
    left:          isMobile  ?  '51.7%' : '50%',
    bottom:        isMobile  ?  `${ apx.m( 46 ) }`  : `${ apx.l( 33.4 ) }`,
    width:         isMobile  ?  `${ apx.m( 167 ) }` :  `${ apx.l( 130   ) }`,

    borderRadius:  isMobile  ?  `${ apx.m( 20  ) } ${ apx.m( 20 ) } 0 0` : `${ apx.m( 10 ) } ${ apx.m( 10 ) } 0 0`

  }


  return (

    <Root>
      <MainContent>
        <div className="logo_wrapper">
          <a  href="/">
            {role === 'client' && <StyledClientLogo />}
            {role === 'business' && <StyledBusinessLogo />}
            {role === 'therapist' && <StyledTherapistLogo />}
          </a>

          <div style={{ position: 'relative' }}>
            <CorpInfoTitle
              $isActive={isCorpInfoOpened}
              onClick={toggleCorpInfo}
            >
              Контакты
              <IconChevronRight />
            </CorpInfoTitle>

            {isCorpInfoOpened && (
              <CorpInfo>
                <Text>ООО «Поговоримонлайн»</Text>
                <Text>ИНН: 9703099825</Text>
                <Text>
                  Юридический адрес:
                  <br />
                  123001, г.Москва,
                  <br />
                  Ермолаевский пер,д.11, кв.6
                </Text>
              </CorpInfo>
            )}
          </div>
        </div>

        <Communication>
          <Contacts>
            <Email href="mailto:prosto@pogovorim.online">
              prosto@pogovorim.online
            </Email>{' '}
            <noscript>
              <br />
            </noscript>
            <Email href="https://wa.me/+79198707755">написать в WhatsApp</Email>
            {/* <Email href="https://wa.me/+79263833022">написать в WhatsApp</Email> */}
          </Contacts>

          <Links>
            {role !== 'client' && (
              <Link href={{ pathname: URL_PUBLIC.forClients, query: router.query }}>
                <a>Клиентам</a>
              </Link>
            )}{' '}
            <noscript>
              <br />
            </noscript>
            {role !== 'therapist' && (
              <Link href={{ pathname: URL_PUBLIC.forTherapists, query: router.query }}>
                <a>Психологам</a>
              </Link>
            )}{' '}
            <noscript>
              <br />
            </noscript>
            {role !== 'business' && (
              <Link href={{ pathname: 'https://b2b.pogovorim.online/', query: router.query }}>
                <a style={{ textWrap: 'nowrap' }}>Для бизнеса</a>
              </Link>
            )}
          </Links>
        </Communication>

        <Socials>
          <Link passHref href="https://www.youtube.com/@pogovorim.online">
            <YouTube />
          </Link>

          <Link passHref href="https://dzen.ru/pogovorimonline">
            <Dzen />
          </Link>

          <Link passHref href="https://t.me/PogovorimOnline">
            <TelegramOutline />
          </Link>
        </Socials>

        {role === 'client' && (

          <SelectSpecialist

            options = { options }
            customStyles = { customStyles }
            dropdownListStyles = { customDropdownListStyles }

          />

        )}

        {role === 'therapist' && (
          <StyledLinkButton
            href={URL_PUBLIC.therapistSignUp}
            query={{...router.query, onlyRegistration: 'true'}}
            target="_blank"
            rel="noopener noreferrer"
          >
            Заполнить анкету
          </StyledLinkButton>
        )}
        {role === 'business' && (
          <StyledButton onClick={() => openModal(UseBusinessModalEnum.Request)}>
            Оставить заявку
          </StyledButton>
        )}
      </MainContent>

      <AdditionalContent>
        <AdditionalLinks>
          {role !== 'client' && (
            <Link href={{ pathname: URL_PUBLIC.forClients, query: router.query }}>
              <a>Клиентам</a>
            </Link>
          )}{' '}
          <noscript>
            <br />
          </noscript>
          {role !== 'therapist' && (
            <Link href={{ pathname: URL_PUBLIC.forTherapists, query: router.query }}>
              <a>Психологам</a>
            </Link>
          )}{' '}
          <noscript>
            <br />
          </noscript>
          {role !== 'business' && (
            <Link href={{ pathname: 'https://b2b.pogovorim.online/', query: router.query }}>
              <a style={{ textWrap: 'nowrap' }}>Для бизнеса</a>
            </Link>
          )}
        </AdditionalLinks>
        <Copyright>© {new Date().getFullYear()} Поговорим онлайн</Copyright>
        <AllRights>Все права защищены</AllRights>

        { !isMedsiUser && //TODO сделать для medsi вёрсткой документы

          <Rights>
            <Link
              href={
                role === 'therapist'
                  ? URL_PUBLIC.expertTermsOfUse
                  : URL_PUBLIC.userTermsOfUse
              }
            >
              <a target="_blank" rel="noopener noreferrer">
                Условия использования
              </a>
            </Link>{' '}
            <noscript>
              <br />
            </noscript>
            <Link href={URL_PUBLIC.privacyPolicy}>
              <a target="_blank" rel="noopener noreferrer">
                Политика конфиденциальности
              </a>
            </Link>
          </Rights>

        }
      </AdditionalContent>
      {modal}
    </Root>
  );
};

export default Footer;

const Root = styled.footer`
  width: 100%;
  background-color: ${COLORS.white};
`;

const MainContent = styled(Container)`
  width: 100%;
  padding-top: ${apx.d(42)};
  padding-bottom: ${apx.d(30)};
  border-top: ${apx.d(1)} solid ${COLORS.primary300};
  border-bottom: ${apx.d(1)} solid ${COLORS.primary300};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  .logo_wrapper {
    margin-bottom: auto;

    svg {
      width: ${apx.d(175)};
      height: ${apx.d(53)};
    }
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(31)} ${apx.l(31)} ${apx.l(41)} ${apx.l(35)} !important;

    .logo_wrapper svg {
      margin: 0;
      margin-bottom: ${apx.l(32)};

      width: ${apx.l(131)};
      height: ${apx.l(40)};
    }

    .logo_wrapper {
      margin-right: ${apx.l(100)};
    }
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    padding: ${apx.m(31)} ${apx.m(31)} ${apx.m(54)} ${apx.m(35)} !important;

    align-items: center;

    flex-direction: column;
    align-items: center;
    text-align: center;

    .logo_wrapper svg {
      margin: auto;
      margin-bottom: ${apx.m(32)};

      width: ${apx.m(131)};
      height: ${apx.m(40)};
    }

    .logo_wrapper {
      margin: auto;
    }
  }
`;

const StyledClientLogo = styled(ClientLogo)`
  width: ${apx.d(175)};
  height: ${apx.d(53)};
  margin-right: ${apx.d(100)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    width: ${apx.l(131)} !important;
    height: ${apx.l(40)} !important;
    margin-right: ${apx.l(75)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    width: ${apx.m(131)} !important;
    height: ${apx.m(40)} !important;
  }
`;

const StyledBusinessLogo = styled(BusinessLogo)`
  width: ${apx.d(175)};
  height: ${apx.d(7553)};
  margin-right: ${apx.d(120)}; ;
`;

const StyledTherapistLogo = styled(TherapistLogo)`
  width: 173px;
  height: 60px;
  margin-right: 120px;

  @media (max-width: ${BreakpointsEnum.md}px) {
    width: 105px;
    height: 36px;
    margin-bottom: 0px;
  }
`;

const Email = styled.a`
  ${TYPOGRAPHY.bodyRegular16};

  margin-bottom: ${apx.d(18)} !important;
  margin-top: ${apx.d(16)} !important;
  width: fit-content;

  color: ${COLORS.dark800};
  display: block;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;

  a {
    ${TYPOGRAPHY.bodyMedium20};
    color: ${COLORS.dark800};
  }

  a:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    order: 0;
    width: 100%;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    display: none;
  }
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${apx.d(7)};
  align-items: center;
  height: ${apx.d(60)};

  margin: 0 ${apx.d(30)} 0 auto;

  cursor: pointer;

  a {
    ${TYPOGRAPHY.bodyMedium20};
    color: ${COLORS.dark800};
  }

  a:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    position: absolute;
    right: ${apx.l(23)};
    top: ${apx.l(94)};
    gap: ${apx.l(12)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    position: absolute;
    top: ${apx.m(110)};
    left: 0;
    right: 0;
    margin: auto;
    justify-content: center;
    gap: ${apx.m(8)};
  }
`;

const StyledLinkButton = styled(ButtonLink)`
  padding: ${apx.d(18)} ${apx.d(80)};
  border-radius: ${apx.d(90)};
  white-space: nowrap;
  font-size: ${apx.d(16)} !important;
  line-height: ${apx.d(21)} !important;
  font-weight: 700 !important;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-left: auto !important;

    margin-left: auto;
    padding: ${apx.l(13)} ${apx.l(44)} !important;

    margin-left: auto;
    font-size: ${apx.l(12)} !important;
    line-height: ${apx.l(16)} !important;
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin: 0 auto !important;
    padding: ${apx.m(17)} ${apx.m(45)} !important;
    margin-top: ${apx.m(28)} !important;

    font-size: ${apx.m(14)} !important;
    line-height: ${apx.m(21)} !important;
    border-radius: ${apx.m(90)};
  }
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  width: fit-content;
  padding: ${apx.d(18)} ${apx.d(75)};
  border-radius: ${apx.d(20)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(18)} ${apx.l(75)};
    border-radius: ${apx.l(10)};
    font-size: ${apx.l(13)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin: 0 auto !important;
    margin-top: ${apx.m(25)} !important;
    padding: ${apx.m(18)} ${apx.m(75)};
    border-radius: ${apx.m(10)};
    font-size: ${apx.m(13)};
  }
`;

const AdditionalContent = styled(Container)`
  padding-top: ${apx.d(51)};
  padding-bottom: ${apx.d(50)};
  ${TYPOGRAPHY.footnoteRegular16};
  color: ${COLORS.dark800};
  display: flex;
  align-items: center;

  a {
    text-decoration-line: underline;

    &:before {
      content: '|';
      margin: 0 ${apx.d(8)};
    }
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    padding: ${apx.l(15)} ${apx.l(28)} ${apx.l(78)} ${apx.l(35)} !important;
    font-size: 12px !important;
  }

  @media ( max-width: ${BreakpointsEnum.sm}px ) {

    flex-direction: column;
    align-items: center;
    padding: ${apx.m(35)} 0 ${apx.m(80)} 0 !important;

  }

`;

const AdditionalLinks = styled.div`
  display: none;

  a {
    ${TYPOGRAPHY.bodyMedium20};
    color: ${COLORS.dark800};
    text-decoration: none;
    &:before {
      content: none;
    }
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${apx.m(23)};

    a {
      font-size: ${apx.m(15)};
      line-height: ${apx.m(17)};
      margin-bottom: ${apx.m(23)};
    }

    a:first-child {
      margin-bottom: ${apx.l(17)};
    }
    a:last-child {
      margin-bottom: ${apx.l(23)};
    }
  }
`;

const Copyright = styled.p`
  display: inline-block;
  white-space: nowrap;
  font-size: ${apx.d(16)};
  line-height: ${apx.d(21)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(12)};
    line-height: ${apx.l(16)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: ${apx.m(12)};
    line-height: ${apx.m(16)};
  }
`;

const AllRights = styled.p`
  margin-left: auto;
  white-space: nowrap;
  font-size: ${apx.d(16)};
  line-height: ${apx.d(21)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(12)};
    line-height: ${apx.l(16)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: ${apx.m(12)};
    line-height: ${apx.m(16)};
    text-align: center;
    width: 100%;
    margin-top: ${apx.m(15)};
    margin-bottom: ${apx.m(5)};
  }
`;

const Rights = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    white-space: nowrap;
    font-size: ${apx.d(16)} !important;
    line-height: ${apx.d(30)} !important;
  }

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    a {
      font-size: ${apx.l(12)} !important;
      line-height: ${apx.l(22)} !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    display: flex;
    flex-direction: column;
    gap: ${apx.m(5)};
    align-items: center;

    a {
      font-size: ${apx.m(12)} !important;
      line-height: ${apx.m(22)} !important;
      &::before {
        content: none;
      }
    }
  }
`;

// const CorpInfoTitle = styled.div<{ $isActive: boolean }>`
//   margin-top: 34px;
//   margin-bottom: 12px;
//   ${TYPOGRAPHY.bodyMedium12};
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   column-gap: 6px;
//   :hover {
//     cursor: pointer;
//   }

//   > svg {
//     margin-bottom: 0px;
//     margin-left: -4px;
//   }

//   ${({ $isActive }) =>
//     $isActive &&
//     css`
//       > svg {
//         transform: rotate(90deg) scale(0.6);
//       }
//     `};
// `;

const Contacts = styled.div`
  margin-right: ${apx.d(110)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-right: 0;
    margin-bottom: ${apx.d(28)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    display: flex;
    flex-direction: column;

    a:nth-child(1) {
      order: 2 !important;
      margin-bottom: 0 !important;
    }

    a:nth-child(2) {
      order: 1 !important;
    }

    margin-bottom: ${apx.m(11)};
  }
`;

const Communication = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;

    a,
    div {
      margin: 0 !important;
    }

    a {
      margin-bottom: 18.5px !important;
    }

    ${Links} a:last-child {
      margin-bottom: 0 !important;
    }

    a {
      font-size: ${apx.l(12)} !important;
      font-weight: 400 !important;
      color: '#001F1B' !important;
    }
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    flex-direction: column;

    a {
      margin-bottom: ${apx.m(11)} !important;

      font-size: ${apx.m(12)} !important;
      line-height: ${apx.m(17)} !important;
    }
  }
`;

const CorpInfoTitle = styled.div<{ $isActive: boolean }>`
  margin-top: ${apx.d(90)};
  margin-bottom: 12px;
  ${TYPOGRAPHY.bodyMedium12};
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
  :hover {
    cursor: pointer;
  }

  > svg {
    margin-bottom: 0px;
    margin-left: -4px;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      > svg {
        transform: rotate(90deg);
      }
    `};

  font-size: ${apx.d(18)};
  line-height: ${apx.d(21)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(14)};
    line-height: ${apx.l(18)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    margin-top: ${apx.m(90)};
    margin-bottom: ${apx.m(20)};
    font-size: ${apx.m(16)};
    line-height: ${apx.m(18)};
    justify-content: center;
  }
`;

const CorpInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${apx.d(270)};

  @media (max-width: ${BreakpointsEnum.md}px) {
    margin-bottom: 16px;
  }

  font-size: ${apx.d(18)};
  line-height: ${apx.d(21)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    max-width: ${apx.l(120)};
    font-size: ${apx.l(14)};
    line-height: ${apx.l(18)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    max-width: unset;
    font-size: ${apx.m(14)};
    line-height: ${apx.m(18)};
  }
`;

const Text = styled.span`
  ${TYPOGRAPHY.bodyRegular12};

  margin-top: 10px;

  :first-child {
    margin-top: initial;
  }

  font-size: ${apx.d(16)};
  line-height: ${apx.d(22)};

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    font-size: ${apx.l(14)};
    line-height: ${apx.l(18)};
  }

  @media (max-width: ${BreakpointsEnum.sm}px) {
    font-size: ${apx.m(14)};
    line-height: ${apx.m(18)};
  }
`;

const IconChevronRight = styled(ChevronRight)`
  width: 22px !important;
  height: 22px !important;
  margin: unset !important;
  margin-top: -2px !important;
  transform: scale(0.8);
`;
