import { FC, memo, useCallback, useEffect, useRef } from 'react';
import { TherapistCard } from './TherapistCard';
import styled from 'styled-components';
import Link from 'next/link';
import { ITherapist } from '@landing/types/home.page';

import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { BreakpointsEnum } from '@landing/types';
import { URL_PUBLIC } from '@/shared/urls';
import apx from '@landing/constants/adaptivepx';

type TherapistListProps = {
  data: ITherapist[];
};

const speed = 1.5;
const videosGap = 2;

const TherapistListComponent: FC<TherapistListProps> = ({ data }) => {
  const isActiveRef = useRef(true);
  const videosGapRef = useRef(0);

  const animation = {
    duration: 11000,
    easing: (t: number) => t,
  };

  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    renderMode: 'performance',
    drag: true,
    slides: {
      perView: 'auto',
      spacing: 15,
    },
    created(s) {
      s.moveToIdx(speed, true, animation);
    },

    updated(s) {
      s.moveToIdx(s.track.details.abs + speed, true, animation);
    },

    animationEnded(s) {
      if (!isActiveRef.current) return;

      s.moveToIdx(s.track.details.abs + speed, true, animation);
    },
  });

  const stopSlider = () => {
    if (!slider?.current?.animator) {
      return;
    }
    slider.current.animator.stop();
    isActiveRef.current = false;
  };

  const stopSliderScroll = useCallback(() => {
    if (!slider?.current?.track) {
      return;
    }

    if (document.documentElement.scrollTop >= 800) {
      stopSlider();
    } else {
      slider.current.moveToIdx(
        slider.current.track.details.abs + speed,
        true,
        animation,
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', stopSliderScroll);

    return () => window.removeEventListener('scroll', stopSliderScroll);
  }, [stopSliderScroll]);

  const chooseIsVideoItem = (item: ITherapist) => {
    if (item.videoKey && videosGapRef.current >= videosGap) {
      videosGapRef.current = 0;
      return true;
    }

    videosGapRef.current = videosGapRef.current + 1;
    return false;
  };

  return (
    <div
      ref={sliderRef}
      className="keen-slider"
      onMouseEnter={stopSlider}
      onMouseLeave={() => {
        if (!slider?.current) {
          return;
        }
        slider.current.moveToIdx(
          slider.current.track.details.abs + speed,
          true,
          animation,
        );
        isActiveRef.current = true;
      }}
    >
      {data.map((slide) => (
        <Slide key={slide.id}>
          <TherapistCard isVideo={chooseIsVideoItem(slide)} data={slide}>
            <Link href={URL_PUBLIC.therapistsDetailPage(slide.url)}>
              Подробнее
            </Link>
          </TherapistCard>
        </Slide>
      ))}
    </div>
  );
};

export const TherapistList: FC<TherapistListProps> = memo(
  TherapistListComponent,
);

const Slide = styled.div.attrs({ className: 'keen-slider__slide' })`
  margin-bottom: ${apx.d(74)};
  z-index: 1;
  overflow: visible !important;

  @media (max-width: ${BreakpointsEnum.tablet}px) {
    margin-bottom: ${apx.l(68)};
  }

  @media (max-width: ${BreakpointsEnum.mb}px) {
    margin-bottom: ${apx.m(50)};
  }

  && {
    overflow-x: unset;
    overflow-y: unset;
  }
`;
